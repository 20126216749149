<template>
  <div>
    <v-layout wrap>
      <v-flex
        xs12
        md12
        sm12
      >
        <material-card
          :title="$t('printVoucher.printLayoutEditor', { '0': templateName })"
          color="green"
          flat
          full-width
          class="bg-white mx-3"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs4
                  md4
                  sm6
                >
                  <v-autocomplete
                    :items="templateList"
                    v-model="templateId"
                    :label="$t('printVoucher.template')"
                    @change="onChangeTemplate"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      {{ $t(data.item.text) }}
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex
                  xs4
                  md4
                  sm6
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        small
                        color="primary"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onAddNewTemplate()">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.add') }}</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        xs4
        md4
      >
        <!-- v-show="templateId" -->
        <div
          class="voucher-print-action-list"
          p-2>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="printVoucherActionHeaders"
            :items="printVoucherActionList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 text-red font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr
                :class="{ 'test-primary': item.itemKind == printVoucherItemKindSelect }"
                style="cursor: pointer;"
                @click="selectRow(item)">
                <td class="text-xs-left">{{ $t(item.text) }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div
          v-show="printVoucherItemKindSelect"
          class="voucher-print-action-list mt-2"
          style="margin-top: 0px !important">
          <templatePrintEVoucherEditModal
            ref="templatePrintEVoucherEditModal"
            @callbackTemplateConfigItem="callbackTemplateConfigItem"
            @emitUpdateTemplateItemImage="emitUpdateTemplateItemImage"
            @onRemoveTemplateItem="onShowRemoveTemplate"
            @onCloseComponent="onCloseComponent"/>
        </div>
      </v-flex>
      <v-flex
        style="overflow:auto"
        fill-height
        xs12
        xs8
        md8
      >
        <layout-print-voucher
          :customer-name="$t('printVoucher.customerName')"
          :customer-email="'customer@sample.com'"
          :voucher-name="$t('printVoucher.sampleTitle')"
          :voucher-price="100000"
          :voucher-type="1"
          :voucher-serial="'VOUCHER-SERIAL'"
          :activation-code="'VOUCHER-QR-CODE'"
          :supplier-name="supplierName"
          :supplier-hotline="supplierPhone"
          :supplier-website="supplierWebsite"
          :expired-date="expiredDate"
          :voucher-description="$t('printVoucher.descriptionText')"
          :voucher-term-of-conditions="$t('printVoucher.termOfConditionsText')"
          :voucher-details="$t('printVoucher.sampleDetails')"
          :logo-supplier-url="supplierImage"
          :logo-agent-url="agentSampleLogo"
          :background-url="bkgndImageSelectedUrl"
          :second-background-url="secondBackgroundUrl"
          :is-hide-price="false"
        />
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <loadingBar :is-loading="isLoading" />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="removeTemplatePrintEVoucher"
    />
    <addTemplatePrintEVoucher
      ref="addTemplatePrintEVoucher"
      @callbackTemplateConfigList="callbackTemplateConfigList"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS } from 'utils/constants'
import moment from 'moment'
import functionUtils, { uploadFileToMediaAgent } from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import LayoutPrintVoucher from 'Components/LayoutPrintVoucher.vue'
import TemplatePrintEVoucherEditModal from './TemplatePrintEVoucherEditModal.vue'
import AddTemplatePrintEVoucher from './AddTemplatePrintEVoucher.vue'
import PrintTemplateItemKind from 'enum/printTemplateItemKind'
import PrintTemplateItemType from 'enum/printTemplateItemType'
import ConfirmModal from 'Components/ConfirmModal'
export default {
  components: {
    LayoutPrintVoucher,
    TemplatePrintEVoucherEditModal,
    AddTemplatePrintEVoucher,
    ConfirmModal
  },
  data: () => ({
    supplierName: null,
    supplierImage: null,
    supplierPhone: null,
    supplierWebsite: null,
    agentSampleLogo: require('@/assets/agent_logo.png'),
    isLoading: false,
    expiredDate: moment().add(DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, 'days').format('DD/MM/YYYY'),
    bkgndImageSelectedUrl: null,
    bkgndImageSelectedFile: null,
    printVoucherActionHeaders: [
      {
        sortable: false,
        text: 'printVoucher.itemName',
        value: 'text',
        align: 'center'
      }
    ],
    printVoucherActionList: [
      {
        'id': null,
        'text': 'printVoucher.actionList.backgroundImage',
        'itemType': PrintTemplateItemType.IMAGE,
        'itemKind': PrintTemplateItemKind.BACKGROUND_IMAGE,
        'value': null },
      {
        'id': null,
        'text': 'printVoucher.actionList.secondPageBackgroundImage',
        'itemType': PrintTemplateItemType.IMAGE,
        'itemKind': PrintTemplateItemKind.BACKGROUND_2ND_IMAGE,
        'value': null }
    ],
    secondBackgroundUrl: null,
    templateItemSelect: null,
    confirmModalTitle: '',
    templateList: [],
    templateId: null,
    templateName: null,
    printVoucherItemKindSelect: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {},
  created () {
    this.supplierName = sessionStorage.getItem('entityName')
    this.supplierImage = sessionStorage.getItem('entityImage')
    this.supplierPhone = sessionStorage.getItem('entityPhone')
    this.supplierWebsite = sessionStorage.getItem('entityWebsite')

    this.getTemplateConfigItem()
    this.getTemplateConfigList()
  },
  methods: {
    /**
     * Upload advertisement image
     */
    uploadBannerImage: async function (file) {
      this.bkgndImageSelectedUrl = await uploadFileToMediaAgent(file, 'eVoucher/printVoucherTemplateConfig')
    },
    /**
     * Select image
     */
    onChangeBackgroundImage: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.bkgndImageSelectedFile = files[0]
      this.bkgndImageSelectedUrl = URL.createObjectURL(files[0])
    },
    /**
     * Format date
     */
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date, null)
    },
    // getRandomCode: function (length) {
    //   return functionUtils.randomString(length)
    // },
    templatePrintEVoucherEdit: function (item) {
      this.$refs.templatePrintEVoucherEditModal.onShowModal(item, this.templateId)
    },
    getTemplateConfigItem: function () {
      let filterPrintTemplate = {
        params: {
          templateId: this.templateId
        }
      }
      this.isLoading = true
      this.GET_PRINT_TEMPLATE_ITEM_CONFIG(filterPrintTemplate).then(
        function (res) {
          let data = res.data
          this.bkgndImageSelectedUrl = null
          this.secondBackgroundUrl = null
          this.printVoucherActionList.forEach(e => {
            let index = data.findIndex(x => x.kind === e.itemKind)
            if (index !== -1) {
              e.value = data[index].value
              e.id = data[index].id
              if (data[index].kind === PrintTemplateItemKind.BACKGROUND_IMAGE) {
                this.bkgndImageSelectedUrl = data[index].value
              } else if (data[index].kind === PrintTemplateItemKind.BACKGROUND_2ND_IMAGE) {
                this.secondBackgroundUrl = data[index].value
              }
            } else {
              e.value = null
              e.id = null
            }
          })
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }
      )
    },
    onShowRemoveTemplate: function (item) {
      this.templateItemSelect = item
      this.confirmModalTitle = this.$t('printVoucher.notiRemoveTemplateItem', { '0': this.$t(item.text) })
      this.$refs.confirmModal.onShowModal()
    },
    removeTemplatePrintEVoucher: function () {
      this.REMOVE_PRINT_TEMPLATE_ITEM_CONFIG({ id: this.templateItemSelect.id }).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.onCloseComponent()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            'styleType': ToastType.ERROR
          })
        }
      )
    },
    callbackTemplateConfigItem: function () {
      this.getTemplateConfigItem()
    },
    emitUpdateTemplateItemImage: function (data) {
      if (data.itemKind === PrintTemplateItemKind.BACKGROUND_IMAGE) {
        this.bkgndImageSelectedUrl = data.imageUrl
      } else if (data.itemKind === PrintTemplateItemKind.BACKGROUND_2ND_IMAGE) {
        this.secondBackgroundUrl = data.imageUrl
      }
    },
    getTemplateConfigList: function () {
      this.isLoading = true
      this.templateList = [{ value: null, text: '---' }]
      this.GET_PRINT_TEMPLATE_CONFIG_LIST().then(
        function (res) {
          let data = res.data
          data.forEach(e => {
            let item = {
              value: e.id,
              text: !functionUtils.isEmptyString(e.name) ? e.name : e.id
            }
            this.templateList.push(item)
          })
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }
      )
    },
    onChangeTemplate: function (data) {
      this.printVoucherItemKindSelect = null
      let index = this.templateList.findIndex(x => x.value === data)
      if (index !== -1) {
        this.templateName = this.templateList[index].text
      }
      this.getTemplateConfigItem()
    },
    onAddNewTemplate: function () {
      this.$refs.addTemplatePrintEVoucher.onShowModal()
    },
    callbackTemplateConfigList: function () {
      this.getTemplateConfigList()
    },
    selectRow: function (item) {
      if (this.templateId) {
        this.printVoucherItemKindSelect = item.itemKind
        this.templatePrintEVoucherEdit(item)
      }
    },
    onCloseComponent: function () {
      this.printVoucherItemKindSelect = null
      this.callbackTemplateConfigItem()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_PRINT_TEMPLATE_ITEM_CONFIG',
      'REMOVE_PRINT_TEMPLATE_ITEM_CONFIG',
      'GET_PRINT_TEMPLATE_CONFIG_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.lb-choose-file {
  display: inline-block;
  background-color: #5cb860 !important;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.2rem;
  cursor: pointer;
  margin-top: .4rem;
}
.btn-upload-file {
  opacity: 0.8;
}
.btn-upload-file:hover {
  opacity: 1 !important;
}

.voucher-print-action-list {
  // width: 210mm !important;
  // height: 297mm !important;
  margin: 10mm 12px !important;
  border: 1px #D3D3D3 solid;
  border-radius: 5px !important;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif !important;
  /* line-height: 1.7 !important; */
}

/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
</style>
