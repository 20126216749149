var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-container',[_c('v-layout',{attrs:{"wrap":""}},[(_vm.fieldType === _vm.fieldTypeObj.IMAGE)?_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('div',[_c('img',{staticClass:"banner-img-class img-responsive",attrs:{"id":"template-img-id","src":_vm.imageFieldData !== null && _vm.imageFieldData !== ''
                  ? _vm.imageFieldData
                  : _vm.noImg,"alt":"banner-img"}}),_c('input',{staticStyle:{"display":"none"},attrs:{"id":"upload-template-img","type":"file","hidden":""},on:{"change":function($event){return _vm.onChangeTemplateImage($event)}}})])]):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.templateItemSelect && _vm.templateItemSelect.id)?_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"color":"warning darken-1"},on:{"click":function($event){return _vm.onRemoveTemplateItem()}}},on),[_c('v-icon',[_vm._v("mdi-sync")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.reset')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"color":"success darken-1"},on:{"click":function($event){return _vm.onConfirm($event)}}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.save')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"color":"error darken-1"},on:{"click":function($event){return _vm.onCloseModal()}}},on),[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.close')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }