<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="600px">
    <v-card>
      <material-card
        :title="$t('common.add')"
        tile
        color="green"
        full-width
      >
        <v-container>
          <v-form
            ref="form"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                md12
              >
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="name"
                  :label="$t('common.name')"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal()"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm($event)"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import validationRules from 'utils/validationRules'
export default {
  data () {
    return {
      isShowModal: false,
      name: null
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {},
  mounted () {},
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    onShowError: function (mess) {
      this.ON_SHOW_TOAST({
        'message': this.$t(mess),
        'styleType': ToastType.ERROR
      })
    },
    /**
     * Confirm
     */
    onConfirm: function (event) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        let data = {
          name: this.name
        }
        this.CREATE_PRINT_TEMPLATE(data).then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.isShowModal = false
            this.$emit('callbackTemplateConfigList')
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        )
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    onShowModal: function () {
      this.isShowModal = true
      this.name = null
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'CREATE_PRINT_TEMPLATE'
    ])
  }
}

</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
  /deep/.required label::after {
    content: " *";
    color: red;
  }
</style>
